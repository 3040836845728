import React, { useState } from "react";
import "./FAQSection.css";

function FAQQuestion({ question, answer }) {
  const [expanded, setExpanded] = useState(false);

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <div className="faq-question" onClick={toggleExpansion}>
        <span className="faq-question-text">{question}</span>
        <span className={`faq-indicator ${expanded ? "minus" : "plus"}`}>
          {expanded ? "-" : "+"}
        </span>
      </div>
      {expanded && <div className="faq-answer">{answer}</div>}
    </>
  );
}

export default FAQQuestion;
