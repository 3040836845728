import './Navbar.css';
import twitterLogo from './media/twitter-logo.svg';
import Logo from './Logo';

function Navbar() {
  return (
    <>
      <nav className="navbar">
        <Logo/>
        <div className="navbar-container">
          <ul className="nav-menu">
            <li className="nav-item">
              <a href="https://twitter.com/Vision2097" target="_blank" rel="noopener noreferrer" className="nav-links">
                <img src={twitterLogo} alt="Twitter" className="twitter-logo" />
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  )
}

export default Navbar;
