import React from 'react';
import './TeamSection.css';
import team1 from './media/team1.png';
import team2 from './media/team2.png';
import team3 from './media/team3.png';

const TeamSection = () => {
  return (
    <div className="team-section">
      <h1>Team2097</h1>
      <div className="team-section-content">
        <div className="team-section-item" key={1}>
          <img src={team1}/>
          <div className="team-section-text">
            <h3>A2097</h3>
            <p>Creative Director</p>
          </div>
        </div>
        <div className="team-section-item" key={2}>
          <img src={team2}/>
          <div className="team-section-text">
            <h3>I2097</h3>
              <p>Web Developer</p>
            </div>
          </div>
        <div className="team-section-item" key={3}>
          <img src={team3}/>
          <div className="team-section-text">
            <h3>Z2097</h3>
            <p>Solidity Developer</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamSection;
