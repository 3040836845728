import React from 'react';
import './LandingSection.css';

import banner from './media/banner.jpg';
import logo from './media/logo.png';
import opensealogo from './media/opensea-logo.svg';

import image1 from './media/image1.png';
import image2 from './media/image2.png';
import image3 from './media/image3.png';
import image4 from './media/image4.png';
import image5 from './media/image5.png';
import image6 from './media/image6.png';
import image7 from './media/image7.png';
import image8 from './media/image8.png';

const LandingSection = () => {
  return (
    <div className="landing-section">
      <div className="landing-section-content">
        <div className="landing-section-text">
          <h1>Explore Vision2097</h1>
          <h4>2097 and beyond</h4>
        </div>
      </div>
      <div className="landing-section-opensea">
          <img src={opensealogo} alt="Logo" className="logo" />
          <div className="header">
            <img src={banner} alt="Header Image" className="header-image" />
            <img src={logo} alt="Profile Picture" className="profile-picture" />
          </div>
          <div className="content-section">
            <h2>Dystopia2097</h2>
            <div className="image-grid">
              <img key={1} src={image1} alt={image1} className="grid-image" />
              <img key={2} src={image2} alt={image1} className="grid-image" />
              <img key={3} src={image3} alt={image1} className="grid-image" />
              <img key={4} src={image4} alt={image1} className="grid-image" />
              <img key={5} src={image5} alt={image1} className="grid-image" />
              <img key={6} src={image6} alt={image1} className="grid-image" />
              <img key={7} src={image7} alt={image1} className="grid-image" />
              <img key={8} src={image8} alt={image1} className="grid-image" />
            </div>
          </div>
        </div>
    </div>
  );
};

export default LandingSection;
