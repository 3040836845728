import React from 'react';
import './Footer.css';
import twitterLogo from './media/twitter-logo.svg'; // import the Twitter logo image file

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-line"></div>
      <div className="footer-line-break"></div>
      <span className="footer-star">&#x2605;</span>
      <div className="footer-content">
        <div className="footer-left">© VISION2097</div>
        <a href="https://twitter.com/Vision2097" target="_blank" rel="noopener noreferrer" className="footer-right">
          <img src={twitterLogo} alt="Twitter" className="twitter-logo" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
