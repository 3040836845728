import React from 'react';
import './Logo.css';

const Logo = () => {
    return (
        <div className="logo-container">
            <div className="text-group">
                <p className="main-text small-text">Vision</p>
                <p className="overlay-text small-text">~~~~~~</p>
            </div>
            <div className="text-group">
                <div className="main-text large-text">
                    <span>20</span><span className="blink">:</span><span>97</span>
                </div>
                
                <p className="overlay-text large-text">88 88</p>
            </div>
        </div>
    );
};

export default Logo;
