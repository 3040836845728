import React from 'react';
import './CollectionSection.css';
import dystopia from './media/dystopia.png'; // import the image file


const CollectionSection = () => {
  return (
    <div className="collection-section">
      <div className="collection-section-content">
        <img src={dystopia} alt="dystopia"/>
        <div className="collection-section-text">
          <h1>DYSTOPIA2097 is part 1 of our odyssey</h1>
          <p>
            The introduction to VISION2097, titled "DYSTOPIA," takes us on a journey filled with twists and turns, featuring some of our team's most personal and distinctly psychedelic work. It showcases unexpected visual arrangements, offering a surrealistic glimpse into the other-worldly web3 odyssey that lies ahead. Within the captivating intersection of AI and NFTs, the inaugural installment unveils a remarkable collection of 297 pieces.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CollectionSection;
