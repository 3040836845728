import './App.css'
import Navbar from './components/Navbar.js'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import LandingSection from './components/LandingSection';
import CollectionSection from './components/CollectionSection';
import TeamSection from './components/TeamSection';
import FAQSection from './components/FAQSection';
import Footer from './components/Footer';
import { SpeedInsights } from '@vercel/speed-insights/react';

function App() {
  return (
    <div className="App">
      <Router>
        <div className="landing-background">
          <div className="content-wrapper">
            <Navbar />
          </div>
          <div className="content-wrapper">
            <LandingSection />
          </div>
        </div>
        <div className="content-wrapper">
          <CollectionSection />
          <TeamSection />
          <FAQSection />
          <Footer />
        </div>
      </Router>
      <SpeedInsights />
    </div>
  );
}

export default App;
