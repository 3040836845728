import React from "react";
import "./FAQSection.css";
import FAQQuestion from "./FAQQuestion";

function FAQ(props) {
  return (
    <div className="faq-section">
      <div className="faq-container">
        <h1>Frequently Asked Questions</h1>
        <FAQQuestion question="Is there a Discord?" answer="Not yet. We are a small team with full time jobs and are currently focusing on building the base of our brand out. This would allow a limited amount of time to maintain the discord and we want to avoid just making a discord without proper attention. However this is on the top of our list of priorities as we build our community." />
        <FAQQuestion question="Will DYSTOPIA2097 be the only collection?" answer="No! We are completely focused on our first collection however the over-arching idea of Vision2097 is to build a number of different collections under the umbrella of Vision2097." />
        <FAQQuestion question="What's the goal for Vision2097?" answer="We are on an exploration of different art formats, utility, and experiences. We are not putting ourselves or our community in a box." />
        <FAQQuestion question="How many secondary royalties is the Vision2097 team taking?" answer="0.5%" />
        <FAQQuestion question="Will there be a membership for Vision2097 that will grant exclusive benefits for all VISION2097 collections?" answer="This is something we are working on. The first collection is free but as we build our brand, we will look to reward our members with special passes." />
      </div>
    </div>
  );
}

export default FAQ;
